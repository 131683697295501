<template>
  <v-app :dark="$store.getters.isDarkTheme">
    <!-- Botão para alternar o tema -->
    <v-btn icon class="theme-toggle-btn" @click="toggleTheme">
      <v-icon>{{ $store.getters.isDarkTheme ? 'mdi-weather-sunny' : 'mdi-weather-night' }}</v-icon>
    </v-btn>

    <!-- Container geral para centralizar logo e v-card -->
    <v-container class="d-flex justify-center align-center fill-height" style="flex-direction: column;">
      <!-- Texto "ADrakNet" -->
      <div class="logo-text" :style="{ color: logoColor }">ADrak<span style="color: red;">Net</span></div>

      <!-- Logo personalizada -->
      <div class="logo mb-4">
        <div class="logo-section logo-wallets">
          <span>WALLETS</span>
        </div>
        <div class="logo-section logo-pay">
          <span>PAY</span>
        </div>
      </div>

      <!-- Card de dados de transação -->
      <v-card class="pa-6" max-width="500">
        <v-card-title class="justify-center">Dados da Transação</v-card-title>
        <v-card-text>
          <v-stepper v-model="step">
            <!-- Cabeçalho dos Steps -->
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">Dados do Cliente</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2">Dados do Comércio</v-stepper-step>
            </v-stepper-header>

            <!-- Conteúdo dos Steps -->
            <v-stepper-items>
              <!-- Step 1: Dados do Cliente -->
              <v-stepper-content step="1">
                <v-form ref="formStep1" v-model="validStep1" lazy-validation>
                  <v-text-field label="E-mail do Cliente" v-model="email" type="email" required
                    :rules="[rules.required, rules.email]"></v-text-field>
                  <v-text-field label="Valor" v-model="valor" type="number" required prefix="R$"
                    :rules="[rules.required]"></v-text-field>
                  <div v-if="btcValue">
                    <p>Valor em BTC: {{ btcValue }} BTC</p>
                  </div>
                </v-form>
                <v-btn color="#e30000" block @click="validateStep1">Próximo</v-btn>
              </v-stepper-content>

              <!-- Step 2: Dados do Lojista -->
              <v-stepper-content step="2">
                <v-form ref="formStep2" v-model="validStep2" lazy-validation>
                  <v-select v-model="tipoChavePix" :items="['Celular', 'CPF/CNPJ', 'E-mail', 'Aleatória']"
                    label="Tipo da chave PIX" required :rules="[rules.required]" @change="applyPixMask"></v-select>
                  <v-text-field label="Chave PIX" v-model="chavePix" :rules="[rules.required]" required
                    hint="Preencha de acordo com o tipo de chave selecionada" persistent-hint></v-text-field>
                  <v-text-field label="Sobrenome / Razão Social" v-model="sobrenomeRazaoSocial" required
                    :rules="[rules.required]"></v-text-field>
                </v-form>

                <v-divider></v-divider>

                <div v-if="valor" class="my-4">
                  <h3 class="subtotal-text">SUBTOTAL: R$ {{ subtotal }}</h3>
                </div>

                <!-- Adicionando espaço entre os botões -->
                <v-row>
                  <v-col>
                    <v-btn color="gray" block class="mr-2" @click="prevStep">Voltar</v-btn>
                  </v-col>
                  <v-col>
                    <v-btn color="#e30000" block @click="validateStep2">Finalizar</v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>

      <!-- Diálogo de confirmação -->
      <v-dialog v-model="dialog" max-width="400">
        <v-card>
          <v-card-title class="text-h5">Pagamento Pendente</v-card-title>

          <v-divider></v-divider>

          <v-card-text class="text-center">
            <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="128"></v-icon>

            <div class="text-h5 font-weight-bold">Confira seu e-mail!</div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="text-none" color="primary" rounded @click="dialog = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',

  data() {
    return {
      step: 1,
      email: '',
      valor: '',
      tipoChavePix: '',
      chavePix: '',
      sobrenomeRazaoSocial: '',
      btcValue: null,
      btcRate: null,
      validStep1: false,
      validStep2: false,
      dialog: false, // Estado para controlar o diálogo
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        email: value => /.+@.+\..+/.test(value) || 'E-mail inválido.',
      },
    };
  },

  computed: {
    // Computed property para alternar a cor do texto "ADrakNet"
    logoColor() {
      return this.$store.getters.isDarkTheme ? 'white' : 'black';
    },

    subtotal() {
      return (this.valor - (this.valor * 0.05)).toFixed(2); // Calcula o subtotal com 5% de desconto
    }
  },

  methods: {
    toggleTheme() {
      this.$store.dispatch('toggleTheme');
      this.$vuetify.theme.dark = this.$store.getters.isDarkTheme;
    },
    async fetchBTCRate() {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=brl'
        );
        this.btcRate = response.data.bitcoin.brl;
        this.convertToBTC();
      } catch (error) {
        console.error('Erro ao buscar a taxa de conversão BTC:', error);
      }
    },
    convertToBTC() {
      if (this.valor && this.btcRate) {
        this.btcValue = ((this.valor / this.btcRate) * 1.0105).toFixed(8);
      } else {
        this.btcValue = null;
      }
    },
    validateStep1() {
      const isValid = this.$refs.formStep1.validate();
      if (isValid) {
        this.nextStep();
      }
    },
    validateStep2() {
      const isValid = this.$refs.formStep2.validate();
      if (isValid) {
        this.dialog = true; // Abrir o diálogo ao clicar em finalizar
      }
    },
    applyPixMask() {
      // Limpa o campo de chave PIX ao alterar o tipo de chave
      this.chavePix = '';
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
}

.theme-toggle-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

.subtotal-text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.logo-text {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  user-select: none;
}

.logo {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-bottom: 20px;
  user-select: none;
}

.logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 0;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: white;
  user-select: none;
  box-sizing: border-box;
  text-align: center;
}

.logo-wallets {
  background-color: black;
}

.logo-pay {
  background-color: #e30000;
}

.logo-wallets span {
  color: #e30000;
}

.logo-pay span {
  color: white;
}

@media screen and (max-width: 500px) {
  .theme-toggle-btn {
    left: 325px;
  }
}
</style>
